@keyframes up-and-down{
  0% {transform: translateY(0)}
  25% {transform: translateY(-5px)}
  50% {transform: translateY(0)}
  75% {transform: translateY(-5px)}
  100% {transform: translateY(0)}
}

.dashboard-home-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .page-title {
    font-size: 1.5rem;
    font-weight: 300;
    margin-top: 0;
  }

  .job-count-status-bar {
    padding: 1rem;
    border: 1.5px solid goldenrod;
    background-color: lightyellow;
    color: goldenrod;
    font-size: 1.25rem;
    font-weight: 500;
    border-radius: 10px;
    text-align: center;
    margin: 1rem 0 2rem 0;
  }

  .hide {
    display: none !important;
  }

  .documents-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;


    .document-item {
      &-link {
        padding: .5rem;
        text-decoration: none;
        color: black;
        margin: .5rem;
        border-radius: 5px;
        width: 18rem;
        background-color: rgba(200, 200, 200, 0.2);
        transition: ease-in .2s;

        &:hover {
          background-color: rgba(200, 200, 200, 0.4);
          cursor: pointer;

          .new-converted-tag {
            // top: -18px;
            border-bottom: 1px solid lightgrey;
            animation-name: up-and-down;
            animation-duration: 1s;
            animation-iteration-count: infinite;
          }

        }

      }

      &-details {
        word-break: break-all;
        display: flex;
        align-items: center;
        position: relative;

        .new-converted-tag {
          width: 40px;
          height: 12px;
          background-color: gold;
          position: absolute;
          right: 0;
          top: -15px;
          padding: .25rem;
          text-align: center;
          font-size: .6rem;
          font-weight: 700;
          border-radius: 5px;
          z-index: 0;
          color: white;
          transition: ease .2s;
        }

        
        .icon-container {
          display: flex;
          justify-content: center;
          align-items: center;

        }

        .details-container {
          display: flex; 
          flex-wrap: wrap;

          .file-name {
            font-size: 0.8rem;
            font-weight: 500;
            margin: 0.25rem;
            flex-basis: 100%;

          }
  
          .file-detail {
            font-size: 0.6rem;
            font-weight: 300;
            margin: .15rem;
            padding: .15rem;
            background-color: rgba(0, 150, 99, 0.2);
            border-radius: 3px;
            height: max-content;
            
          }

        }

      }
      
    }

  }

}