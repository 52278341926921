.search-field-form-group {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;

  .search-bar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    margin-bottom: .5rem;
    border: 1px lightgrey solid;
    padding: 0.5rem 1rem;
    width: 65%;
    max-width: 30rem;

    &:focus-within {
      background-color: whitesmoke;
      border-width: 2px;
    }

    .icon-container {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 10%;
    }
      

    .search-field-input  {
      width: 90%;
      min-height: 1.25rem;
      font-size: 1.15rem;
      border: none;
      color: grey;

      &:focus {
        outline: none;
        background-color: whitesmoke;
      }

    }
  }
}
