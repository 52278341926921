@import '../../common_styles/colors.scss';

.form-container {
  min-width: 35%;
  background-color: white;
  padding: 1.5rem;
  box-shadow: 5px 5px 20px rgba(200, 200, 200, 0.5);
  border-radius: 10px;
  margin-bottom: 2rem;

  .form-title {
    font-size: 1.5rem;
    font-weight: 400;
    margin: 0 0 2rem 0;
  }

  .confirm-signup-form {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .links-container {
    display: flex;
    flex-direction: column;
    
    a {
      text-decoration: none;
      color: block;
      margin: .25rem;
      transition: ease .2s;
      line-height: 1.5rem;

      &:active, &:visited {
        color: black;
      }

      &:hover {
        color: $dark-golden-rod;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}

