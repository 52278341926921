@import '../../common_styles/colors.scss';

.error-message-bar {
  background-color: red;
  width: 100%; 
  height: 3rem;
  line-height: 3rem;
  text-align: center;
  color: white;
  font-size: 1.15rem;
  vertical-align: bottom;
  transition: ease 0.2s;
}

.error-message-bar-hide {
  height: 0 !important;
}

.dashboard-page {
  width: 100%;
  display: flex;
  flex-direction: column; 
  align-items: center;

  .loading-gif-container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .loading-message-box {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 20rem;
      padding: 1rem;
      font-weight: 300;
      font-size: 1.25rem;
      text-align: center;

    }
  }

  .page-top-container {
    width: 100%;
    height: 10rem;
    background-color: floralwhite;
    display: flex;
    justify-content: center;
    border-bottom: 2px solid whitesmoke;
    margin-bottom: 3rem;

    .header-navigation-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      max-width: 80rem;
      padding: 2rem;
      
      .header-container {
        color: $golden-rod;
        text-align: center;
        margin-top: 1.5rem;
  
        .main-header {
          margin: 0 0 2rem 0;
  
        }
      }
  

      .top-navigation-panel-container {
        display: flex;
        justify-content: center;

        .top-navigation-panel {
          display: flex;
          justify-content: space-around;
          // padding: 0 3rem;
          box-shadow: 5px 5px 20px lightgrey;
          border-radius: 5px;
          background-color: white; 
          box-sizing: border-box;
          // border-bottom: 3px solid goldenrod;
          margin: 0.5rem;
  
          .nav-button {
            padding: .75rem 1.75rem;
            color: white;
            font-size: 1rem;
            font-weight: 500;
            margin: 0;
            transition: ease-out .2s;
            color: goldenrod;
            border-radius: 5px;
            text-decoration: none;
  
            
            &:hover {
              cursor: pointer;
              text-shadow: 1px 1px 2px gold;
              background-color: lightyellow;
            }
          }

          .nav-selected {
            background-color: lightyellow;
          }
  
          .nav-button.signout {
            color: red;
            padding: .75rem 1rem;
            border-radius: 5px;
            &:hover {
              cursor: pointer;
              text-shadow: 1px 1px 2px lightsalmon;
              color: white;
              background-color: red;
            }
          }
        }
      }
    }
  }

  .page-bottom-container {
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: white;;

    .page-content-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      max-width: 80rem;
      padding: 2rem;

    }
  }
}
