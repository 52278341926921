@import '../../common_styles/colors.scss';

.error-message-bar {
  background-color: red;
  width: 100%; 
  height: 3rem;
  line-height: 3rem;
  text-align: center;
  color: white;
  font-size: 1.15rem;
  vertical-align: bottom;
  transition: ease 0.2s;
}

.error-message-bar-hide {
  height: 0 !important;
}

.signin-page {
  background-color: $golden-rod-yellow;
  padding: 5% 0 2rem 0;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: content-box;

  .header-container {
      color: $golden-rod;
      text-align: center;

      .main-header {
        margin: 0 0 2rem 0;

      }

      .sub-header {
        font-size: 1.5rem;
        font-style: italic;
        font-weight: 300;
        color: $dark-golden-rod;
        
      }

  }

  .footer-container {
    font-size: 1rem;
    color: grey;
    text-align: center;
  }
}
