.file-field-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;

  .field-label {
    padding-bottom: .25rem;
    font-size: 1.2rem;
    font-weight: 300;
    margin-bottom: .25rem;
  }

  .field-input {
    min-height: 1.25rem;
    border: none;
    margin-bottom: .5rem;
    font-size: 1.15rem;
    padding: .5rem .5rem .25rem .5rem;
    border-radius: 5px 5px 0 0;
  }
}
