.confirmation-codes-container {
  width: 100%;
  background-color: whitesmoke;
  margin-bottom: 1.5rem;
  border-radius: 5px;

  .verification-label {
    width: 100%;
    text-align: center;
    margin: 1.25rem 0 0 0;
    font-weight: 300;
  }

  .six-digit-number-verification-fields {
    width: 95%;
    display: flex;
    justify-content: center;
    padding: .5rem;

    .number-verification-field {
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 5px;
      line-height: 2rem;
      font-size: 1.25rem;
      // border: 1px solid lightgrey;
      border: none;
      box-shadow: 2px 2px 10px lightgrey;
      color: darkslategrey;
      text-align: center;
      margin: 1rem;
      
      &:focus {
        outline: none;
        background-color: whitesmoke;
        border: 1px solid darkgray;
      }
    }
  }
}