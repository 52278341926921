.pdf-converter-page {
  min-width: 30rem;
  .form-title {
    font-weight: 300;
    font-size: 1.25rem;
    padding: 0 1rem 1rem 1rem;

  }

  .pdf-converter-form-container {
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 5px 5px 15px rgba(192, 168, 199, 0.5);
  }
}
