.date-field-container {
  .date-field {
    width: 95%;
    min-height: 1.25rem;
    border: none;
    border-bottom: 1px solid grey;
    margin-bottom: .5rem;
    font-size: 1.15rem;
    padding: .5rem .5rem .25rem .5rem;
    border-radius: 5px 5px 0 0;

    &:focus {
      outline: none;
      background-color: whitesmoke;
    }
  }

  .field-label {
    padding-bottom: .25rem;
    font-size: 1.15rem;
    font-weight: 300;
    margin-bottom: .25rem;
  }


}